body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-day-grid-event {
  cursor: pointer;
}

.DateRangePicker_picker__fullScreenPortal {
  z-index: 1500 !important;
}